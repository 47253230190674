
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import Util from '../../../lib/util'
    import AbpBase from '../../../lib/abpbase'
    import Vessel from '../../../store/entities/vessel'
    import VesselHistoryVm from "../../../store/entities/vesselhistoryvm";

    @Component
    export default class VesselHistoryForm extends AbpBase {
        @Prop({type: Boolean, default: false}) value: boolean;
        private history: Array<VesselHistoryVm> = new Array<VesselHistoryVm>();

        async visibleChange(value: boolean) {
            if (!value) {
                this.$emit('input', value);
            } else {
                let vessel = Util.extend(true, {}, this.$store.state.vessel.editVessel);
                this.history = await this.$store.dispatch('vessel/history', vessel.id);
            }
        }

        get list() {
            return this.history;
        }

        columns = [{
            title: this.L('Name'),
            key: 'vesselName'
        }, {
            title: this.L('Date'),
            key: 'date',
            width: 250,
            slot: 'date'
        }, {
            title: this.L('User'),
            key: 'userName'
        }]
    }
