
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import Util from '../../../lib/util'
    import AbpBase from '../../../lib/abpbase'
    import Vessel from '../../../store/entities/vessel'

    @Component
    export default class VesselForm extends AbpBase {
        @Prop({type: Boolean, default: false}) value: boolean;
        @Prop({type: Boolean, default: false}) public toEdit: boolean;
        vessel: Vessel = new Vessel();
        private countryList: any;
        private vesselTypeList: any;

        data() {
            return {
                countryList: [],
                vesselTypeList: [],
                updateCount: 0,
            }
        };

        async mounted() {
            this.countryList = await this.$store.dispatch('country/lookUp');
            this.vesselTypeList = await this.$store.dispatch('vesseltype/lookUp')
        }

        save() {
            (this.$refs.vesselForm as any).validate(async (valid: boolean) => {
                if (valid) {
                    await this.$store.dispatch({
                        type: this.toEdit ? 'vessel/update' : 'vessel/create',
                        data: this.vessel
                    });
                    (this.$refs.vesselForm as any).resetFields();
                    this.$emit('save-success');
                    this.$emit('input', false);
                }
            })
        }

        cancel() {
            (this.$refs.vesselForm as any).resetFields();
            this.$emit('input', false);
        }

        visibleChange(value: boolean) {
            if (!value) {
                this.$emit('input', value);
            } else {
                if (this.toEdit) {
                    this.vessel = Util.extend(true, {}, this.$store.state.vessel.editVessel);
                }
                else {
                    this.vessel = new Vessel();
                }
            }
        }

        vesselRule = {
            name: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Name')), trigger: 'blur'}],
            immoNumber: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Name')), trigger: 'blur'}],
            mmsiNumber: [{required: true, message: this.L('FieldIsRequired', undefined, this.L('Name')), trigger: 'blur'}]
        }
    }
