
    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import Util from '../../../lib/util'
    import AbpBase from '../../../lib/abpbase'
    import Vessel from '../../../store/entities/vessel'

    @Component
    export default class VesselNameForm extends AbpBase {
        @Prop({type: Boolean, default: false}) value: boolean;
        vessel: Vessel = new Vessel();
        private vesselName: string = null;

        async save() {
            (this.$refs.vesselForm as any).validate(async (valid: boolean) => {
                if (valid) {
                    await this.$store.dispatch({
                        type: 'vessel/addName',
                        data: {
                            vesselId: this.vessel.id,
                            name: this.vesselName
                        }
                    });
                    this.vesselName = '';
                    this.$emit('save-success');
                    this.$emit('input', false);
                }
            });

        }

        cancel() {
            this.vesselName = '';
            this.$emit('input', false);
        }

        visibleChange(value: boolean) {
            if (!value) {
                this.$emit('input', value);
            } else {
                this.vessel = Util.extend(true, {}, this.$store.state.vessel.editVessel);
            }
        }

        checkNameInput(rule, value, callback) {
            if (this.vesselName === undefined || this.vesselName === null || this.vesselName === "") {
                return callback(new Error(this.L('FieldIsRequired', undefined, this.L('Type'))));
            }
            return callback();
        }

        vesselNameRule = {
            name: [{
                required: true, validator: this.checkNameInput, trigger: 'blur'
            }]
        }
    }
