

    import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
    import AbpBase from '@/lib/abpbase'
    import PageRequest from '@/store/entities/page-request'
    import VesselForm from './vessel-form.vue'
    import SortingModel from "@/store/entities/sortingmodel";
    import VesselNameForm from "@/views/setting/vessel/vesselname-form.vue";
    import VesselHistoryForm from "@/views/setting/vessel/vesselhistory-from.vue";

    class PageVesselRequest extends PageRequest {
        countryId: number | null;
    }

    @Component({
        components: {VesselForm, VesselNameForm, VesselHistoryForm}
    })

    export default class Vessel extends AbpBase {
        private countryList: any;


        async changeSort(data) {
            if (this.pagerequest.sorting == null) {
                this.pagerequest.sorting = [];
            }
            let item = this.pagerequest.sorting.find(x => x.fieldName === data.key)
            if (item == null) {
                let sortingModel = new SortingModel();
                sortingModel.fieldName = data.key;
                sortingModel.isDescendingDirection = data.order === "desc";
                this.pagerequest.sorting.push(sortingModel)
            } else {
                item.isDescendingDirection = data.order === "desc";
            }
            await this.getpage()
        }

        async handleUpload(file) {
            let formData = new FormData();
            formData.append('file', file);
            await this.$store.dispatch({
                type: 'vessel/uploadFile',
                data: formData
            })
            await this.getpage();
        }

        async search() {
            await this.pageChange(1);
        }

        addName() {
            this.addNameModalShow = true;
        }

        edit() {
            this.editModalShow = true;
        }

        history() {
            this.historyModalShow = true;
        }

        pagerequest: PageVesselRequest = new PageVesselRequest();

        createModalShow: boolean = false;
        editModalShow: boolean = false;
        addNameModalShow: boolean = false;
        historyModalShow: boolean = false;

        data() {
            return {
                file: null,
                countryList: [],
                updateCount: 0,
            }
        };

        async mounted() {
            this.countryList = await this.$store.dispatch('country/lookUp');
        }

        get list() {
            return this.$store.state.vessel.list;
        };

        get loading() {
            return this.$store.state.vessel.loading;
        }

        create() {
            this.createModalShow = true;
        }

        isCountryChange(val: number) {
            if (val === 0)
                this.pagerequest.countryId = null;
            else
                this.pagerequest.countryId = val;
        }

        pageChange(page: number) {
            this.$store.commit('vessel/setCurrentPage', page);
            this.getpage();
        }

        pagesizeChange(pagesize: number) {
            this.$store.commit('vessel/setPageSize', pagesize);
            this.getpage();
        }

        async getpage() {

            this.pagerequest.maxResultCount = this.pageSize;
            this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

            await this.$store.dispatch({
                type: 'vessel/getAll',
                data: this.pagerequest
            })
        }

        get pageSize() {
            return this.$store.state.vessel.pageSize;
        }

        get totalCount() {
            return this.$store.state.vessel.totalCount;
        }

        get currentPage() {
            return this.$store.state.vessel.currentPage;
        }

        columns = [{
            title: this.L('Name'),
            key: 'name',
            sortable: 'custom'
        }, {
            title: this.L('IMMONumber'),
            key: 'immoNumber',
            sortable: 'custom'
        }, {
            title: this.L('MMSINumber'),
            key: 'mmsiNumber',
            sortable: 'custom'
        }, {
            title: this.L('Flag'),
            key: 'flagName',
            sortable: 'custom'
        }, {
            title: this.L('Type'),
            key: 'typeName',
            sortable: 'custom'
        }, {
            title: this.L('Actions'),
            key: 'Actions',
            width: 300,
            render: (h: any, params: any) => {
                return h('div', [
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small',
                            disabled: !this.hasChangeNamePermission()
                        },
                        style: {
                            marginRight: '5px'
                        },
                        on: {
                            click: async () => {
                                this.$store.commit('vessel/edit', await this.$store.dispatch({
                                    type: 'vessel/get',
                                    data: params.row.id
                                }));
                                this.addName();
                            }
                        }
                    }, this.L('Add name')),
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small',
                            disabled: !this.hasEditPermission()
                        },
                        style: {
                            marginRight: '5px'
                        },
                        on: {
                            click: async () => {
                                this.$store.commit('vessel/edit', await this.$store.dispatch({
                                    type: 'vessel/get',
                                    data: params.row.id
                                }));
                                this.edit();
                            }
                        }
                    }, this.L('Edit')),
                    h('Button', {
                        props: {
                            type: 'primary',
                            size: 'small'
                        },
                        style: {
                            marginRight: '5px'
                        },
                        on: {
                            click: async () => {
                                this.$store.commit('vessel/edit', await this.$store.dispatch({
                                    type: 'vessel/get',
                                    data: params.row.id
                                }));
                                this.history();
                            }
                        }
                    }, this.L('History')),
                    h('Button', {
                        props: {
                            type: 'error',
                            size: 'small',
                            disabled: !this.hasDeletePermission()
                        },
                        on: {
                            click: async () => {
                                this.$Modal.confirm({
                                    title: this.L('Tips'),
                                    content: this.L('Delete Subdivision Confirm'),
                                    okText: this.L('Yes'),
                                    cancelText: this.L('No'),
                                    onOk: async () => {
                                        await this.$store.dispatch({
                                            type: 'vessel/delete',
                                            data: params.row
                                        })
                                        await this.getpage();
                                    }
                                })
                            }
                        }
                    }, this.L('Delete'))
                ])
            }
        }]

        async created() {
            await this.getpage();
        }

        hasAddPermission() {
            return this.hasPermission("Pages.Vessels.Add");
        }

        hasEditPermission() {
            return this.hasPermission("Pages.Vessels.Edit");
        }

        hasDeletePermission() {
            return this.hasPermission("Pages.Vessels.Delete");
        }

        hasChangeNamePermission() {
            return this.hasPermission("Pages.Vessels.ChangeName");
        }

    }
